<template>
  <div :class="$style.root">
    <div class="mb-4">
      <h1 v-if="$route.meta.title" class="mb-0">{{$route.meta.title}}</h1>
    </div>
    <div class="mb-4 clearfix">
      <div class="float-left">
          <VBtn depressed @click="$router.push({ name: 'pkosmr/CounterpartyListView' })">
              <VIcon>mdi-arrow-left</VIcon> Назад
          </VBtn>
      </div>
    </div>
    <div :class="$style.inner">
      <SurveyList
          :headers="headers"
          :items="surveyList.items"
          :count="surveyList.count"
          :page="page"
          :size="size"
          :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { debounce, map, get } from 'lodash-es';
import SurveyList from '@/components/pkosmr/SurveyList/SurveyList.vue';

export default {
  name: 'SurveyListView',
  components: {
    SurveyList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
      surveyList: 'pkosmr/surveyList',
      loading: 'pkosmr/pending',
    }),
    headers() {
      return [
        { text: 'ИНН', value: 'inn', sortable: false, needFilter: true },
        { text: 'Организация', value: 'name', alias: 'companyName', sortable: false, needFilter: true },
        { text: 'Дата', value: 'date', sortable: false,  },
        ...map(get(this.surveyList, 'items.0.questions', []), (question) => ({
          text: question.label,
          value: `questions.${question.code}`,
          alias: question.code,
          sortable: false,
        }))
      ];
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
  },
  methods: {
    ...mapActions({
      fetchSurveyList: 'pkosmr/fetchSurveyList',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchSurveyList({ page, size, filter });
    }, 500),
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
